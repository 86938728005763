<template>
  <div class="terms-wrapper">
      <h2 class="terms-header">Публічна оферта</h2>
    <div class="terms-content">
      <p>
          Цей договір є офіційною та публічною пропозицією "Продавця" - закладу громадського харчування укласти договір купівлі-продажу Товару, представленого за допомогою цифрового меню, доступ до якого надається через QR-коди в закладі та посилання в соціальній мережі закладу громадського харчування або ж за допомогою додатку QRDelivery. 
      </p>
      <p>
          Даний договір є публічним, тобто відповідно до статті 633 Цивільного кодексу України, його умови є однаковими для всіх покупців незалежно від їх статусу (фізична особа, юридична особа, фізична особа-підприємець) без надання переваги одному покупцю перед іншим. 
      </p>
      <p>
          Шляхом укладення цього Договору Покупець в повному обсязі приймає умови та порядок оформлення замовлення, оплати товару, доставки товару, повернення товару, відповідальності за недобросовісне замовлення та усі інші умови договору. 
      </p>
      <p>
          Договір вважається укладеним з моменту натискання кнопки "Відправити Замовлення" на сторінці оформлення замовлення в Розділі "Кошик" і отримання Покупцем від Продавця підтвердження замовлення в електронному вигляді.
      </p>
      <h4>
          I. ВИЗНАЧЕННЯ ТЕРМІНІВ
      </h4>
      <p>
          1.1. Публічна оферта (далі - "Оферта") - публічна пропозиція Продавця, адресована невизначеному колу осіб, укласти з Продавцем договір купівлі-продажу товару дистанційним способом (далі - "Договір") на умовах, що містяться в цій Оферті.
      </p>
      <p>
          1.2. Товар або Послуга – об'єкт угоди сторін, який був обраний Покупцем за допомогою цифрового меню або Додатку QRDelivery та поміщений у кошик.
      </p>
      <p>
          1.3. "Сервіс", "сервіс QRWaiter" — комплекс інтегрованих комп'ютерних систем, які поєднують апаратні засоби, програмне забезпечення, комунікаційні технології та технічних рішень, спрямованих на оптимізацію роботи закладів громадського харчування за допомогою QR-кодів; створений з метою посередництва для укладення договорів роздрібної та оптової купівлі-продажу на підставі ознайомлення Покупця із запропонованим Продавцем описом Товару за допомогою цифрового меню Додатку QRDelivery. 	
      </p>
      <p>
          Веб-сторінка Сервісу доступна у мережі Інтернет за адресою: <a href="https://qrwaiter.com.ua">https://qrwaiter.com.ua</a>
      </p>
      <p>
          Цифрове меню – меню закладу громадського харчування, доступне для здійснення замовлення та укладення договорів роздрібної та оптової купівлі-продажу, яке містить детальний опис Товару, що пропонується Покупцю і до якого надається доступ через  QR-коди в закладі громадського харчування та посилання в соціальній мережі закладу громадського харчування або ж за допомогою додатку QRDelivery.
      </p>
      <p>
          1.4. Покупець – дієздатна фізична особа, яка досягла 18 років, отримує інформацію від Продавця, здійснює замовлення щодо купівлі товару, що представлений за допомогою цифрового меню або Додатку QRDelivery для цілей, що не пов'язані зі здійсненням підприємницької діяльності, або юридична особа чи фізична особа-підприємець.
      </p>
      <p>
          1.5. Продавець – заклад громадського харчування {{ offert.cafe_name }}, якому Товариство з обмеженою відповідальністю "КЮАРВЕЙТЕР", що внесене до Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань під номером 43164813, надає послугу доступу до Сервісу QRWaiter для укладення договорів купівлі-продажу з Покупцями в мережі Інтернет.
      </p>
      <h4>II. ЗАГАЛЬНІ ПОЛОЖЕННЯ</h4>
      <p>
          2.1. Наведена нижче інформація є офіційною пропозицією (офертою) будь-якій фізичній особі (далі - Покупець) укласти договір купівлі-продажу товарів за допомогою цифрового меню або Додатку QRDelivery. Зазначений договір є публічним. Відповідно до статті 633 Цивільного кодексу України його умови однакові для всіх покупців.
      </p>
      <p>
          2.2. Згідно зі статтею 642 Цивільного Кодексу України повним і беззастережним прийняттям умов даної пропозиції (оферти), що підтверджує укладення Договору купівлі-продажу товарів на запропонованих нижче умовах, є факт оформлення та підтвердження замовлення.
      </p>
      <p>
          2.3. Оформленням замовлення за допомогою Сервісу Покупець підтверджує узгодження і безумовне прийняття ним умов цього пропозиції (оферти).
      </p>
      <p>
          2.4. Укладаючи Договір (тобто акцептуючи умови справжнього Пропозиції (Пропоновані можливості) шляхом оформлення замовлення, Покупець підтверджує наступне:
          <br>
а) Покупець цілком і повністю ознайомлений, і згоден з умовами цієї пропозиції (оферти);
<br>
б) він дає дозвіл на збір, обробку та передачу персональних даних.
      </p>
      <p>
          2.5. Надаючи свої персональні дані при реєстрації або оформленні замовлення, Покупець надає Продавцеві свою добровільну згоду на обробку, використання (у тому числі і передачу) своїх персональних даних, а також вчинення інших дій, передбачених Законом України "Про захист персональних даних", без обмеження терміну дії такої згоди.
      </p>
      <p>
          2.6. Продавець зобов'язується не розголошувати отриману від Покупця інформацію. Не вважається порушенням надання Продавцем інформації контрагентам і третім особам, що діють на підставі договору з Продавцем, в тому числі і для виконання зобов'язань перед Покупцем, а також у випадках, коли розкриття такої інформації встановлено вимогами чинного законодавства України.
      </p>
      <p>
          2.7. Покупець несе відповідальність за підтримання своїх персональних даних в актуальному стані. Продавець не несе відповідальності за неякісне виконання або невиконання своїх зобов'язань у зв'язку з неактуальністю інформації про Покупця або невідповідністю її дійсності.
      </p>
      <h4>
          IIІ. ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН
      </h4>
      <p>
          3.1. Продавець зобов’язаний:
      </p>
      <p>
          3.1.1. Передати Покупцеві товар у відповідності до умов цього Договору та замовлення
Покупця.
      </p>
      <p>
          3.1.2. Не розголошувати будь-яку приватну інформацію про Покупця і не надавати доступ
до цієї інформації третім особам, за винятком випадків, передбачених законодавством та
під час виконання Замовлення Покупця.
      </p>
      <p>
          3.2. Продавець має право:
      </p>
      <p>
          3.2.1 Змінювати умови цього Договору, а також ціни на Товари та послуги, в односторонньому порядку, розміщуючи їх на сайті. Всі зміни набувають чинності з моменту їх публікації у цифровому меню або Додатку QRDelivery.
      </p>
      <p>
          3.3 Покупець зобов’язується:
      </p>
      <p>
          3.3.1 До моменту укладення Договору ознайомитися зі змістом Договору, умовами Договору і цінами, запропонованими Продавцем у цифровому меню або Додатку QRDelivery.
      </p>
      <p>
          3.3.2 На виконання Продавцем своїх зобов’язань перед Покупцем останній повинен повідомити всі необхідні дані, що однозначно ідентифікують його як Покупця, і достатні для доставки Покупцеві замовленого Товару.
      </p>
      <h4>IV. ЦІНА ТОВАРУ</h4>
      <p>
          4.1. Ціна на кожну позицію Товару вказана у відповідному розділі цифрового меню закладу громадського харчування та в Додатку QRDelivery.
      </p>
      <p>
          4.2. Продавець має право в односторонньому порядку змінити ціну на будь-яку позицію Товару.
      </p>
      <p>
          4.3. У разі зміни ціни на замовлений Товар Продавець зобов'язується негайно проінформувати Покупця про зміну ціни Товару до фактичного отримання Товару Покупцем.
      </p>
      <p>
          4.4. Покупець має право підтвердити або анулювати Замовлення на придбання Товару, якщо ціна змінена Продавцем після оформлення Замовлення.
      </p>
      <p>
          4.5. Зміна Продавцем ціни на оплачений Покупцем Товар не допускається.
      </p>
      <p>
          4.6. Продавець вказує вартість доставки Товару при оформленні замовлення за допомогою цифрового меню закладу громадського харчування  або в Додатку QRDelivery. 
      </p>
      <p>
          4.7. Зобов'язання Покупця по оплаті Товару вважаються виконаними з моменту надходження Продавцю коштів.
      </p>
      <p>
          4.8. Розрахунки між Продавцем і Покупцем за Товар здійснюються способами, зазначеними в цифровому меню або в Додатку QRDelivery в розділі "СПОСІБ ОПЛАТИ", в тому числі – шляхом безготівкової оплати на розрахунковий рахунок Продавця із застосуванням відповідних платіжних систем.
      </p>
      <h4>
          V. ОФОРМЛЕННЯ ЗАМОВЛЕННЯ
      </h4>
      <p>
          5.1. Замовлення Товару здійснюється Покупцем за допомогою цифрового меню або Додатку QRDelivery.
      </p>
    <p>
        5.2. При оформленні замовлення Покупець зобов'язується надати щонайменше наступну інформацію:
    </p>
    <p>
        5.2.1. прізвище, ім'я, по батькові Покупця або вказаної ним особи (одержувача);
    </p>
    <p>
        5.2.2. адреса, за якою слід доставити Товар (якщо доставка до адреси Покупця);
    </p>
    <p>
        5.2.3. контактний телефон.
    </p>
    <p>
        5.3. Найменування, кількість, артикул, ціна обраного Покупцем Товару вказуються в кошику цифрового меню або  Додатку QRDelivery.
    </p>
    <p>
        5.4. Якщо Продавцю необхідна додаткова інформація, він має право запросити її у Покупця через Оператора. 
    </p>
    <p>
        5.5. Ухвалення Покупцем умов цієї Оферти здійснюється за допомогою внесення Покупцем відповідних даних в реєстраційну форму кошика цифрового меню або Додатку QRDelivery. Після оформлення Замовлення через Оператора дані про Покупця реєструються в базі даних Продавця.
    </p>
    <p>
        5.6. Покупець несе відповідальність за достовірність наданої інформації при оформленні Замовлення.
    </p>
    <p>
        5.7. Договір купівлі-продажу дистанційним способом між Продавцем і Покупцем вважається укладеним з моменту електронного оформлення замовлення за допомогою цифрового меню або Додатку QRDelivery.
    </p>
    <h4>VІ. ДОСТАВКА І ПЕРЕДАЧА ТОВАРУ ПОКУПЦЮ</h4>
    <p>
        6.1. Способи, порядок і терміни доставки товарів вказанують у відповідному розділі кошика цифрового меню або Додатку QRDelivery. 
    </p>
    <p>
        6.2. Самовивіз товару:
    </p>
    <p>
        6.2.1. Після формування Замовлення покупець може розрахуватися і отримати свій товар за адресою закладу громадського харчування або вказаною адресою під час оформлення замовлення за допомогою цифрового меню або Додатку QRDelivery.
    </p>
    <p>
        6.2.2. Право власності та ризик випадкової втрати або пошкодження товару переходить до Покупця або його Представника з моменту отримання товару Покупцем.
    </p>
    <p>
        6.3. Доставка/передання товару Покупцю здійснюється власними силами закладу громадського харчування згідно з умовами доставки, або із залученням третіх осіб (перевізника).
    </p>
    <p>
        6.4. При отриманні товару Покупець повинен у присутності представника закладу громадського харчування (кур'єра) перевірити відповідність Товару якісним і кількісним характеристикам, (найменування товару, кількість, комплектність, термін придатності).
    </p>
    <p>
        6.5. Покупець або Представник Покупця під час приймання товару зобов’язаний оглянути Товар і в разі наявності претензій до кількості товару, зовнішнього вигляду і асортименту товару зобов’язаний негайно заявити про такі претензії. У разі, якщо такі претензії не заявлені негайно під час прийняття Товару, в подальшому Покупець не має право заявляти їх, якщо інше не передбачено Розділом  VIІ цієї оферти.
    </p>
    <h4>VІI. ПОВЕРНЕННЯ ТОВАРУ</h4>
    <p>
        7.1. Покупець має право відмовитися від непідакцизного товару в будь-який час до його передачі, а після передачі непідакцизного товару - в порядку і на умовах, визначених Законом України "Про захист прав споживачів" та чинним законодавством України.
    </p>
    <p>
        7.2. Повернення непідакцизного товару належної якості можливе у випадку, якщо збережено його товарний вигляд, споживчі властивості, а також документ, що підтверджує факт покупки і умови замовлення зазначеного товару.
    </p>
    <p>
        7.3. Замовник не має права відмовитися від товару належної якості, що має індивідуально-визначені властивості, якщо зазначений товар може бути використаний виключно Споживачем, який його придбав, (в т.ч. не стандартні, за бажанням Замовника, розміри та інше). Підтвердженням того, що товар має індивідуально-визначені властивості, є відмінність розмірів товару та інших характеристик, що вказані в цифровому меню або Додатку.
    </p>
    <p>
        7.4. При відмові Покупця від непідакцизного товару належної якості Продавець повертає кошти в розмірі вартості такого Товару, за винятком витрат продавця на доставку товару, який повертається.
    </p>
    <p>
        7.5. Повернення суми, зазначеної в п.7.4. здійснюється одночасно з поверненням товару.
    </p>
    <h4>VIIІ. ВІДПОВІДАЛЬНІСТЬ СТОРІН</h4>
    <p>
        8.1. Продавець не несе відповідальності за шкоду, заподіяну Покупцеві внаслідок неналежного використання Товарів, попередньо замовлених за допомогою Сервісу QR (цифрове меню/Додаток QRDelivery) і придбаних у Продавця.
    </p>
    <p>
        8.2. Продавець не несе відповідальності за неналежне, несвоєчасне виконання Замовлень і своїх зобов'язань в разі надання Покупцем недостовірної або помилкової інформації при оформленні Замовлення.
    </p>
    <p>
        8.3. Продавець і Покупець несуть відповідальність за виконання своїх зобов'язань відповідно до чинного законодавства України і положень цього Договору.
    </p>
    <p>
        8.4. Продавець або Покупець звільняються від відповідальності за повне або часткове невиконання своїх зобов'язань, якщо невиконання є наслідком форс-мажорних обставин як: війна або військові дії, землетрус, повінь, пожежа та інші стихійні лиха, що виникли незалежно від волі Продавця і / або Покупця після укладення цього договору. Сторона, яка не може виконати свої зобов'язання, негайно повідомляє про це іншу Сторону.
    </p>

    <h4>ІХ. ІНШІ УМОВИ</h4>
    <p>9.1. Цей договір укладено на території України і діє відповідно до чинного законодавства України.</p>
    <p>9.2. Усі спори, що виникають між Покупцем і Продавцем, вирішуються шляхом переговорів. У випадку недосягнення врегулювання спірного питання шляхом переговорів, Покупець та/або Продавець мають право звернутися за вирішенням спору до судових органів відповідно до чинного законодавства України.</p>
    <p>
        9.3. Продавець має право вносити зміни до цього Договору в односторонньому порядку, передбаченому п. 3.2.1. Договору. Крім того, зміни до Договору також можуть бути внесені за взаємною згодою Сторін в порядку, передбаченому чинним законодавством України.
    </p>
      <h4>
          Х. РЕКВІЗИТИ ПРОДАВЦЯ
      </h4>
      <p>
          <b>{{ offert.company_type}} {{ offert.company_name }}</b>
          <br>
          код ЄДРПОУ: {{ offert.company_code }}
          <br>
          Місцезнаходження за адресою: {{ offert.company_address }}
          <br>
          Телефон: <a :href="`tel:${offert.company_phone}`">
              {{ offert.company_phone }}
          </a>
          <br>
          <!-- Адреса електронної пошти: <a href="mailto:team@qrwaiter.com.ua">team@qrwaiter.com.ua</a> -->
          <!-- <br> -->
          <br>
      </p>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        offert: Object
    }
}
</script>

<style>

</style>