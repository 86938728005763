<template>
  <div class="terms-wrapper">
    <h2 class="terms-header">Публичная оферта</h2>
    <div class="terms-content">
            <p>
                Этот договор является официальным и публичным предложением «Продавца» - заведения общественного питания заключить договор купли-продажи Товара, представленного с помощью цифрового меню, доступ к которому предоставляется через QR-коды в заведении и ссылки в социальной сети заведения общественного питания или же с помощью приложения QRWaiter.
            </p>
            <p>
                Данный договор является публичным, то есть согласно статье 426 Гражданского кодекса Российской Федерации, его условия одинаковы для всех покупателей независимо от их статуса (физическое лицо, юридическое лицо, физическое лицо-предприниматель) без оказания отдельным покупателям какого-либо предпочтения или преимуществ. 
            </p>
            <p>
                Путем заключения настоящего Договора Покупатель в полном объеме принимает условия и порядок оформления заказа, оплаты товара, доставки товара, возврата товара, ответственности за недобросовестный заказ и все другие условия договора.
            </p>
            <p>
                Договор считается заключенным с момента нажатия кнопки «Отправить заказ» на странице оформления заказа в Разделе «Корзина» и получения Покупателем от Продавца подтверждения заказа в электронном виде.
            </p>
            <h4>
                 I. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ
            </h4>
            <p>
                1.1. Публичная оферта (далее - «Оферта») - публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом договор купли-продажи Товара дистанционным способом (далее - «Договор») на условиях, содержащихся в этой Оферте.
            </p>
            <p>
                1.2. Товар или Услуга - объект соглашения сторон, который был избран Покупателем с помощью цифрового меню или приложения QRWaiter и помещен в корзину.
            </p>
            <p>
                1.3. «Сервис», «сервис QRWaiter» - комплекс интегрированных компьютерных систем, объединяющих аппаратные средства, программное обеспечение, коммуникационные технологии и технических решений, направленных на оптимизацию работы заведений общественного питания с помощью QR-кодов; создан с целью посредничества для заключения договоров розничной и оптовой купли-продажи на основании ознакомления Покупателя с предложенным Продавцом описанием Товара с помощью цифрового меню QRWaiter.	
            </p>
            <p>
                Веб-страница Сервиса доступна в сети Интернет по адресу: <a href="https://qrwaiter.com.ua">https://qrwaiter.com.ua</a>
            </p>
            <p>
                Цифровое меню - меню заведения общественного питания, доступное для заказа и заключения договоров розничной и оптовой купли-продажи, которое содержит детальное описание Товара, который предлагается Покупателю и к которому предоставляется доступ через QR-коды в заведении общественного питания и ссылки в социальной сети заведения общественного питания или же с помощью приложения QRWaiter.

            </p>
            <p>
                1.4. Покупатель - дееспособное физическое лицо, достигшее 18 лет, которое получает информацию от Продавца, осуществляет заказ с целью покупки товара, который представлен с помощью цифрового меню или приложения QRWaiter для целей, не связанных с осуществлением предпринимательской деятельности, или юридическое лицо либо физическое лицо-предприниматель.
            </p>
            <p>
                1.5. Продавец - заведение общественного питания {{ offert.cafe_name}}, которому Общество с ограниченной ответственностью "КЮАРВЕЙТЕР", юридическое лицо резидент Украины, идентификационный код: 43164813, предоставляет услугу доступа к Сервису QRWaiter, для заключения договоров купли-продажи с покупателями в сети Интернет.
            </p>
            <p>
                1.6. Оператор – контактное лицо, которое выступает от имени Продавца, для связи с Покупателем.
            </p>
            <h4>
                II. ОБЩИЕ ПОЛОЖЕНИЯ
            </h4>
            <p>
                2.1. Приведенная ниже информация является официальным предложением (офертой) любому физическому или юридическому лицу (далее – Покупатель) заключить договор купли-продажи товаров с помощью цифрового меню или приложения QRWaiter. Указанный договор является публичным. В соответствии со статьей 426 Гражданского кодекса Российской Федерации его условия одинаковы для всех покупателей.
            </p>
            <p>
                2.2. Согласно статье 438 Гражданского кодекса Российской Федерации полным и безоговорочным принятием условий данного предложения (оферты), что подтверждает заключение договора купли-продажи товаров на предложенных ниже условиях, является факт оформления и подтверждения заказа.
            </p>
            <p>
                2.4. Оформлением заказа с помощью Сервиса Покупатель подтверждает согласование и безусловное принятие им условий этого предложения (оферты).
            </p>
            <p>
                2.5. Заключая Договор (то есть акцептуя условия настоящего Предложения (Оферты) путем оформления заказа, Покупатель подтверждает следующее:
            </p>
            <p>
                а) Покупатель целиком и полностью ознакомлен и согласен с условиями этого предложения (оферты);
            </p>
            <p>
                б) он дает разрешение на сбор, обработку и передачу персональных данных.
            </p>
            <p>
                2.6. Предоставляя свои персональные данные при регистрации или оформлении заказа, Покупатель предоставляет Продавцу свое добровольное согласие на обработку, использование (в том числе и передачу) своих персональных данных, а также совершение иных действий, предусмотренных Федеральным законом Российской Федерации «О персональных данных», без ограничения срока действия такого согласия.
            </p>
            <p>
                2.7. Продавец обязуется не разглашать полученную от Покупателя информацию. Не считается нарушением предоставление Продавцом информации контрагентам и третьим лицам, действующим на основании договора с Продавцом, в том числе и для выполнения обязательств перед Покупателем, а также в случаях, когда раскрытие такой информации установлено требованиями действующего законодательства Российской Федерации.
            </p>
            <p>
                2.8. Покупатель несет ответственность за поддержание своих персональных данных в актуальном состоянии. Продавец не несет ответственности за некачественное исполнение или неисполнение своих обязательств в связи с неактуальностью информации о Покупателе или с ее несоответствием действительности.
            </p>
            <h4>
                IIІ. ПРАВА И ОБЯЗАННОСТИ СТОРОН
            </h4>
            <p>
                3.1. Продавец обязан:
            </p>
            <p>
                3.1.1. Передать Покупателю товар в соответствии с условиями настоящего Договора и заказу Покупателя.
            </p>
            <p>
                3.1.2. Не разглашать любую частную информацию о Покупателе и не предоставлять доступ к этой информации третьим лицам, за исключением случаев, предусмотренных законодательством и во время выполнения Заказа Покупателя.
            </p>
            <p>
                3.2. Продавец имеет право:
            </p>
            <p>
                3.2.1. Изменять условия настоящего Договора, а также цены на товары и услуги в одностороннем порядке, помещая их на сайте. Все изменения вступают в силу с момента их опубликования в цифровом меню или приложении QRWaiter.
            </p>
            <p>
                3.3. Покупатель обязуется:
            </p>
            <p>
                3.3.1. К моменту заключения Договора ознакомиться с содержанием Договора, условиями Договора и ценами, предлагаемыми Продавцом в цифровом меню или приложении QRWaiter.
            </p>
            <p>
                3.3.2. Во исполнение Продавцом своих обязательств перед Покупателем последний должен сообщить все необходимые данные, однозначно идентифицирующие его как Покупателя, и достаточные для доставки Покупателю заказанного товара.
            </p>
            <h4>IV. ЦЕНА ТОВАРА</h4>
            <p>
                4.1. Цена на каждую позицию Товара указана в соответствующем разделе цифрового меню заведения общественного питания и в Приложении QRWaiter.
            </p>
            <p>
                4.2. Продавец вправе в одностороннем порядке изменить цену на любую позицию товара.
            </p>
            <p>
                4.3. В случае изменения цены на заказанные товары Продавец обязуется немедленно проинформировать Покупателя об изменении цены Товара до фактического получения Товара Покупателем.
            </p>
            <p>
                4.4. Покупатель имеет право подтвердить или аннулировать заказ на приобретение Товара, если цена изменена Продавцом после оформления Заказа.
            </p>
            <p>
                4.5. Изменение Продавцом цены на оплаченный Покупателем Товар не допускается.
            </p>
            <p>
                4.6. Продавец указывает стоимость доставки Товара при оформлении заказа с помощью цифрового меню заведения общественного питания или в Приложении QRWaiter.
            </p>
            <p>
                4.7. Обязательства Покупателя по оплате Товара считаются выполненными с момента поступления Продавцу средств.
            </p>
            <p>
                4.8. Расчеты между Продавцом и Покупателем за Товар осуществляются способами, указанными в цифровом меню или в Приложении QRWaiter в разделе «Способ оплаты», в том числе - путем безналичной оплаты на расчетный счет Продавца с применением соответствующих платежных систем.
            </p>
            <h4>
                V. ОФОРМЛЕНИЕ ЗАКАЗА
            </h4>
            <p>
                5.1. Заказ Товара осуществляется Покупателем с помощью цифрового меню или приложения QRWaiter.
            </p>
            <p>
                5.2. При оформлении заказа Покупатель обязуется предоставить, как минимум, следующую информацию:
            </p>
            <p>
                5.2.1. фамилия, имя, отчество Покупателя или указанного им лица (получателя)
            </p>
            <p>
                5.2.2. адрес, по которому следует доставить Товар (если доставка до адреса Покупателя)
            </p>
            <p>
                5.2.3. контактный телефон.
            </p>
            <p>
                5.3. Наименование, количество, артикул, цена выбранного Покупателем Товара указываются в корзине цифрового меню или приложения QRWaiter.
            </p>
            <p>
                5.4. Если Продавцу необходима дополнительная информация, он имеет право запросить ее у Покупателя с помощью Оператора.
            </p>
            <p>
                5.5. Принятие Покупателем условий настоящей Оферты осуществляется посредством внесения Покупателем соответствующих данных в регистрационную форму корзины цифрового меню или приложения QRWaiter. После оформления Заказа с помощью Оператора данные о Покупателе регистрируются в базе данных Продавца.
            </p>
            <p>
                5.6. Покупатель несет ответственность за достоверность предоставленной информации при оформлении Заказа.
            </p>
            <p>
                5.7. Договор купли-продажи дистанционным способом между Продавцом и Покупателем считается заключенным с момента электронного оформления заказа с помощью цифрового меню или приложения QRWaiter.
            </p>
            <h4>

VІ. ДОСТАВКА И ПЕРЕДАЧА ТОВАРА ПОКУПАТЕЛЮ
            </h4>
            <p>
                6.1. Способы, порядок и сроки доставки товаров указываются в соответствующем разделе корзины цифрового меню или приложения QRWaiter.
            </p>
            <p>
                6.2. Самовывоз Товара:
            </p>
            <p>
                6.2.1. После формирования Заказа Покупатель может рассчитаться и получить свой Товар по адресу заведения общественного питания, или по адресу, указанному во время оформления заказа с помощью цифрового меню или приложения QRWaiter.
            </p>
            <p>
                6.2.2. Право собственности и риск случайной гибели или повреждения Товара переходит к Покупателю или его представителю с момента получения товара Покупателем.
            </p>
            <p>
                6.3. Доставка / передача товара Покупателю осуществляется собственными силами заведения общественного питания в соответствии с условиями доставки, или с привлечением третьих лиц (перевозчика/курьера).
            </p>
            <p>
                6.4. При получении Товара, Покупатель должен в присутствии представителя заведения общественного питания (перевозчика/курьера), проверить соответствие Товара качественным и количественным характеристикам (наименование товара, количество, комплектность, срок годности).
            </p>
            <p>
                6.5. Покупатель или Представитель Покупателя при приемке товара обязан осмотреть Товар, и, в случае наличия претензий к количеству товара, внешнему виду и ассортименту Товара, обязан немедленно заявить о таких претензиях. В случае, если такие претензии не заявлены немедленно при принятии Товара, в дальнейшем Покупатель не вправе заявлять их, если иное не предусмотрено разделом VII этой оферты.
            </p>
            <h4>VІI. ВОЗВРАТ ТОВАРА</h4>
            <p>
                7.1. Покупатель вправе отказаться от неподакцизного товара в любое время до его передачи, а после передачи неподакцизного товара - в порядке и на условиях, определенных Федеральным законом Российской Федерации «О защите прав потребителей» и действующим законодательством Российской Федерации.
            </p>
            <p>
                7.2. Возвращение неподакцизного товара надлежащего качества возможно в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт покупки и заказа указанного товара.
            </p>
            <p>
                7.3. Покупатель не имеет права отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно потребителем, который его приобрел (в т.ч. не стандартные, по желанию Покупателя, размеры и т.д.). Подтверждением того, что Товар имеет индивидуально-определенные свойства, является различие размеров товара и других характеристик, указанных в цифровом меню или приложении.
            </p>
            <p>
                7.4. При отказе Покупателя от неподакцизного товара надлежащего качества, Продавец возвращает средства в размере стоимости такого Товара, за исключением расходов Продавца на доставку товара, который возвращается.
            </p>
            <p>
                7.5. Возвращение суммы, указанной в п.7.4., осуществляется одновременно с возвратом товара.
            </p>
            <h4>
                VIIІ. ОТВЕТСТВЕННОСТЬ СТОРОН

            </h4>
            <p>
                8.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования Товаров, предварительно заказанных с помощью Сервиса QRWaiter (цифровое меню / Приложение QRWaiter) и приобретенных у Продавца.
            </p>
            <p>
                8.2. Продавец не несет ответственности за ненадлежащее, несвоевременное выполнение поставки Товара (Заказов) и своих обязательств в случае предоставления Покупателем недостоверной или ложной информации при оформлении Заказа.
            </p>
            <p>
                8.3. При решении споров и конфликтов, которые прямо или непрямо связаны с исполнением Продавцом и Покупателем этого Договора, стороны руководятся личным правом (lex societatis) Продавца, и положениями настоящего Договора.
            </p>
            <p>
                8.4. Продавец или Покупатель освобождаются от ответственности за полное или частичное неисполнение своих обязательств, если неисполнение является следствием форс-мажорных обстоятельств, таких как: война или военные действия, землетрясение, наводнение, пожар и другие стихийные бедствия, возникшие независимо от воли продавца и / или покупателя после заключения настоящего договора. Сторона, которая не может выполнить свои обязательства, немедленно сообщает об этом другой Стороне.
            </p>
            <h4>
                ІХ. ДРУГИЕ УСЛОВИЯ
            </h4>
            <p>
                9.1. Настоящий договор считается заключенным на территории Продавца и действует в соответствии с действующим законодательством и личным правом (lex societatis) Продавца.
            </p>
            <p>
                9.2. Все споры, возникающие между Покупателем и Продавцом, решаются путем переговоров. В случае невозможности урегулирования спорного вопроса путем переговоров, Покупатель и / или Продавец вправе обратиться за разрешением спора в судебные органы в соответствии с действующим законодательством и личным правом (lex societatis) Продавца.
            </p>
            <p>
                9.3. Продавец имеет право вносить изменения в настоящий Договор в одностороннем порядке, предусмотренном п. 3.2.1. Договора. Кроме того, изменения в Договор также могут быть внесены по взаимному согласию Сторон в порядке, предусмотренном действующим законодательством места заключения настоящего Договора.
            </p>
            <h4>Х. РЕКВИЗИТЫ ПРОДАВЦА</h4>
            <p>
                <b>{{ offert.company_type}} {{ offert.company_name }}</b>
                <br>
                <template v-if="offert.company_code">ОКПО: {{ offert.company_code }}</template>
                <br>
                <template v-if="offert.company_tax_id">ИНН: {{ offert.company_tax_id }}</template>
                <br>
                Фактический адрес: {{ offert.company_address }}
                <br>
                Контактный телефон: <a :href="`tel:${offert.company_phone}`">
                    {{ offert.company_phone }}
                </a>
                <br>
                <template v-if="offert.company_email">Электронная почта: <a :href="`mailto:${offert.company_email}`">{{ offert.company_email }}</a></template>
                <!-- Адреса електронної пошти: <a href="mailto:team@qrwaiter.com.ua">team@qrwaiter.com.ua</a> -->
                <!-- <br> -->
                <br>
            </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offert: Object,
  },
};
</script>

<style>
</style>