<template>
  <div class="terms-wrapper">
    <OffertUA :offert="offert" v-if="offert.country == 'UA'"/>
    <OffertRU :offert="offert" v-else-if="offert.country == 'RU'"/>
    <OffertEN :offert="offert" v-else/>
  </div>
</template>

<script>
import PaymentAPI from '@/api/payment'

import OffertUA from "@/components/offerts/OffertUA.vue"
import OffertRU from "@/components/offerts/OffertRU.vue"
import OffertEN from "@/components/offerts/OffertEN.vue"

export default {
    data() {
        return {
            offert: {}
        }
    },
    methods: {
        fetchData() {
            PaymentAPI.getOffertInfo(
                this.$route.params.cafe_id,
                this.$route.params.menu_id
            ).then(data => {
                this.offert = data.offert;
            }).catch(err => {

            });
        }
    },
    mounted() {
        this.fetchData();
    },
    computed: {

    },
    components: {
        OffertUA,
        OffertRU,
        OffertEN

    }
};
</script>

<style>
body {
    background: rgb(29, 29, 29);
}
</style>

<style>
.terms-wrapper {
  width: 90%;

  margin-left: 5%;
  margin-top: 5vh;
}

.terms-content {
  color: #ededed;
  font-size: 2vh;
  margin-bottom: 5vh;
}

.terms-header {
  color: #464646;
  font-size: 6vh;
}

.terms-content ul li {
  margin-left: 3vh;
}

.terms-content kbd {
  margin-top: 5vh;
}

.terms-content h4 {
  margin-top: 2.5vh;
}
</style>