<template>
  <div class="terms-wrapper">
    <h2 class="terms-header">PUBLIC OFFER AGREEMENT</h2>
    <div class="terms-content">
      <p>
        This agreement is an official and public offer of the «Seller» - a
        catering establishment – to enter into an Agreement for the sale and
        purchase of the Goods presented by means of a digital menu, which is
        accessed through QR codes within the premises of the establishment, as
        well as through links in the social network webpages of the
        establishment, or by means of QRWaiter app.
      </p>
      <p>
        This agreement shall be deemed public in terms that its conditions are
        similar for all Buyers regardless of their legal status (individual,
        legal entity, individual entrepreneur) without any preferences for one
        Buyer over another.
      </p>
      <p>
        This offer shall be considered accepted upon the condition that the
        Buyer expressly accepts all terms in full and unreservedly, without any
        exceptions and/or limitations, such as the conditions and procedure for
        placing an order, payment for goods, delivery of goods, return of goods,
        liability for an unfair order and all other terms of the agreement as
        set out below, and as such it shall be treated as a binding bilateral
        written contract executed for the sale of goods (hereinafter referred to
        as the «Agreement»).
      </p>
      <p>
        The agreement is considered to be concluded from the moment when, upon
        pressing the «Submit order» button on the ordering page in the «Cart»
        section, the Buyer receives an electronic on-screen message of order
        confirmation from the Seller.
      </p>
      <h4>I. DEFINITION OF TERMS</h4>
      <p>
        1.1. Public offer (hereinafter referred to as the «Offer») – a public
        offer of the Seller addressed to an indefinite circle of people to
        conclude an agreement with the Seller for the sale and purchase of the
        Goods remotely (hereinafter referred to as the - the «Agreement») on the
        terms set out below in this Offer.
      </p>
      <p>
        1.2. Product or Goods - an object of the agreement between the parties,
        which was selected by the Buyer using the digital menu or QRWaiter app
        and which was placed in the shopping cart.
      </p>
      <p>
        1.3. «Service», «QRWaiter service» - a complex of integrated computer
        systems that combine hardware, software, communication technologies and
        technical solutions aimed at optimizing the work of catering
        establishments using QR codes; created for the purpose of mediation for
        the conclusion of retail and wholesale contracts based on the Buyer`s
        familiarization with the description of the Goods proposed by the
        Seller, using the QRWaiter digital menu.
      </p>
      <p>
        The QRWaiter Service trademarks are protected from copying and
        simulation under national and international laws and are not to be
        reproduced or copied without the express written permission of its
        owners. The QRWaiter ordering application is owned and operated by the
        licensor according to the relevant terms and conditions of use.
      </p>
      <p>
        <b>
          The website of the Service is available on the Internet at:
          <a href="https://qrwaiter.com.ua">https://qrwaiter.com.ua</a>
        </b>
      </p>
      <p>
        Digital menu - a menu of a catering establishment, available for ordering and concluding contracts for retail and wholesale purchase and sale, which contains a detailed description of the Goods that are offered to the Buyer and which is accessed through QR codes in the catering establishment as well as through links in the social network webpages of the establishment, or by means of QRWaiter app.
      </p>
      <p>
        The content referring to specific products (e.g. food items), arrangement and texts layout of the Digital menu and/or ordering process, the trademarks, and any other content, are proprietary and are protected according with the legal regulations in force and cannot be used in any way without the express written permission of the Seller.
      </p>
      <p>
        1.4. The Buyer - a capable individual who has reached the age of eighteen, receives information from the Seller, places an order for the purchase of goods, which are presented using the digital menu or the QRWaiter app, for the purposes not related to business activities, as well as a legal entity or an individual entrepreneur.
      </p>
      <p>
        1.5. The Seller – a catering establishhment {{offert.cafe_name}}, located at: {{ offert.company_address }}, company code {{ offert.company_code }}, to which the Limited Liability Company «QRWaiter», a legal entity duly incorporated and acting under the laws of Ukraine, identification code: 43164813, or any representatives or affiliated companies thereof, provides access to QRWaiter Service for concluding purchase and sale agreements between the Seller and the Buyers through the Internet.
      </p>
      <p>
        1.6. Operator - a contact person who acts on behalf of the Seller in order to communicate with the Buyer.
      </p>
      <h4>II. GENERAL PROVISIONS</h4>
      <p>
        2.1. The provisions below constitute an official offer to any individual, or a legal entity or an individual entrepreneur (hereinafter referred to as the «Buyer») to conclude a purchase and sale agreement for goods using the digital menu or the QRWaiter app. The present agreement is public and provides for conditions, which are identical for all Buyers. 
      </p>
      <p>
        Any software service/software is used and/or downloaded at your own risk. If you are in any doubt as to the suitability of the software service/software to be used and/or downloaded it is recommended that you obtain specialist advice before using and/or downloading it.  
      </p>
      <p>
        2.2. According to relevant provisions of the applicable law in force the fact of placing and confirming the order shall constitute full and unconditional acceptance of the terms of this offer, which confirms the conclusion of purchase and sale agreement of goods on the conditions set out below. 
      </p>
      <p>
        2.3. By placing an order using the Service, the Buyer confirms the agreement and unconditional acceptance of the terms of this offer.
      </p>
      <p>
        2.4. By concluding the Agreement (that is, accepting the terms of this Offer by placing an order), the Buyer confirms the following:
      </p>
      <p>
        a) The Buyer is fully familiar and agrees to the terms of this offer;
      </p>
      <p>
        b) The Buyer gives permission to collect, process and transfer personal data as set out by the applicable laws for the purposes of fulfilment of this Agreement by the Buyer.
      </p>
      <p>
        2.5. By providing his or her personal data when registering or placing an order, the Buyer provides the Seller with his/her voluntary consent to the processing, use (including transfer) of his or her personal data, as well as to perform other actions provided by the relevant provisions of the applicable laws, as well as by the Privacy policy of the Limited Liability Company «QRWaiter» and that of the Seller without limitation of the term of such consent. 
      </p>
      <p>
        The amount of Buyer’s personal data to be processed and included into the Seller’s personal data database is defined by the parties as information about Buyer and/or terms of the Agreement received by the Seller while establishing relations with the Buyer. By signing this Agreement the Buyer also consents to the change of purpose of Buyer’s personal data processing defined by the Agreement by placing corresponding notification at official web-resources of the Seller. By signing this Agreement the Buyers confirm that they are informed on their Personal data being included into Personal data database with the purpose set in this clause and that they are informed on their rights as defined by the applicable European and other laws on personal data protection, as well as by the applicable privacy policies.
      </p>
      <p>
        2.6. The Seller undertakes not to disclose the information received from the Buyer. The transfer of information by the Seller to the counterparties and third parties acting on the basis of an agreement with the Seller, including the cases where such disclosure is required to fulfill the Seller’s obligations to the Buyer, as well as in cases where the disclosure of such information is established by the requirements of the applicable legislation, shall not be considered as a violation.
      </p>
      <p>
        2.7. The Buyer is responsible for keeping his/her personal data up to date. The Seller is not responsible for poor-quality performance or failure to fulfill obligations due to the outdated information about the Buyer or the inconsistency thereof.
      </p>
      <p>
        2.8. The Seller and the Buyer hereby provide mutual guarantees of their legal capacity, as required to enter into this Agreement for the sale of the goods.
      </p>
      <p>
        2.9. Any Goods provided through the Service are done so on an «as is» and «if available» basis and the Seller expressly excludes any warranties, conditions, representations or other terms with respect to the ordering or the content or products displayed on it, whether express or implied, unless expressly stated to the contrary.
      </p>
      <p>
        2.10. The pictures of the Goods are for presentation only. The ordered products may have differences (e.g. color, form, etc.) towards the photos existing in the Digital Menu. The Seller is not liable in any way if the description of products is not complete.
      </p>
      <h4>
        IIІ. RIGHTS AND OBLIGATIONS OF THE PARTIES
      </h4>
      <p>
        3.1. The Seller is obliged:
      </p>
      <p>
        3.1.1. To transfer the goods to the Buyer in accordance with the terms of this Agreement and the Buyer`s order.
      </p>
      <p>
        3.1.2. Not to disclose any personal information about the Buyer and not to provide access to this information to third parties, unless otherwise is provided for by the applicable law and in the process of fulfilment of the Buyer`s Order.
      </p>
      <p>
        3.2. The Seller has the right:
      </p>
      <p>
        3.2.1. To unilaterally change the terms of this Agreement, as well as the prices of goods and services. The Seller may unilaterally change the conditions of this Public Offer, without receiving the Buyer`s prior consent, provided that the updated conditions are posted at the website, in the digital menu or the QRWaiter app. All changes take effect from the moment they are posted on the website, in the digital menu or the QRWaiter app. 
      </p>
      <p>
        3.3 The Buyer is obliged:
      </p>
      <p>
        3.3.1. To get informed before concluding the Agreement about the content of the Agreement, the terms thereof  and the prices offered by the Seller in the digital menu or QRWaiter app.
      </p>
      <p>
        3.3.2. To pay for the Goods ordered from the Seller.
      </p>
      <p>
        3.3.3. For correct fulfillment by the Seller of his obligations to the Buyer, the Buyer shall provide all necessary data that uniquely identifies him/her as the Buyer, and is enough for efficient delivery of the ordered goods to the Buyer. 
      </p>
      <h4>
        IV. THE PRICE OF THE GOODS
      </h4>
      <p>
        4.1. The price for each item of the Goods is indicated in the corresponding section of the digital menu of the catering establishment and in the QRWaiter App.
      </p>
      <p>
        4.2. The Seller has the right to unilaterally change the price for any item of the Goods.
      </p>
      <p>
        4.3. In case the price of the ordered goods is changed, the Seller undertakes to immediately inform the Buyer about the change in the price of the Goods before the actual receipt of the Goods by the Buyer.
      </p>
      <p>
        4.4. The Buyer has the right to confirm or cancel the order for the purchase of the Goods if the price has been changed by the Seller after placing the Order.
      </p>
      <p>
        4.5. The Seller shall not change the price of the Goods if it has been already paid by the Buyer.
      </p>
      <p>
        4.6. The Seller indicates the cost of the delivery of the Goods when the Buyer places an order using the digital menu of the catering establishment or in the QRWaiter App.
      </p>
      <p>
        4.7. The Buyer`s obligations to pay for the Goods shall be considered fulfilled from the moment the funds are received by the Seller.
      </p>
      <p>
        4.8. Transactions between the Seller and the Buyer in relation to payment for the Goods shall be executed by the methods specified in the digital menu or in the QRWaiter App in the «Payment Method» section, including by means of money transfer to the Seller's account using the relevant payment systems.
      </p>
      <h4>
        V. PLACING AN ORDER
      </h4>
      <p>
        5.1. The order of the Goods is made by the Buyer using the digital menu or the QRWaiter app.
      </p>
      <p>
        5.2. When placing an order, the Buyer undertakes to provide at least the following information:
      </p>
      <p>
        5.2.1. surname, name, patronymic of the Buyer or the person (recipient) specified by him/her;
      </p>
      <p>
        5.2.2. address, to which the Goods should be delivered (if delivery is ordered to be made at Buyers address);
      </p>
      <p>
        5.2.3. contact phone number.
      </p>
      <p>
        5.3. The name, quantity, item, price of the Goods selected by the Buyer are indicated in the cart of the digital menu or in the QRWaiter app.
      </p>
      <p>
        5.4. If the Seller requires any additional information, he/she has the right to receive it from the Buyer through the Operator.
      </p>
      <p>
        5.5. The Buyer's acceptance of the terms of this Offer is carried out by the Buyer entering the relevant data into the registration form of the cart in the digital menu or QRWaiter application. After placing the Order through the Operator, the data about the Buyer is registered in the seller's database.
      </p>
      <p>
        5.6. The Buyer is responsible for the accuracy of the information provided to the Seller when placing an Order.
      </p>
      <p>
        5.7. The online sale and purchase agreement between the Seller and the Buyer is considered concluded from the moment of placing of the electronic Order using the digital menu or the QRWaiter application.
      </p>
      <p>
        5.8. The Seller shall make all the necessary diligences to ensure that the information in relation with the ordering is accurate and reliable. However, this cannot be infallible and errors may sometimes occur. The Buyer hereby undertakes to take appropriate steps to verify all information in relation with the digital (online) ordering before using it. 
      </p>
      <h4>
        VІ. DELIVERY AND TRANSFER OF THE GOODS TO THE BUYER
      </h4>
      <p>
        6.1. The methods, procedure and timing of delivery of goods are indicated in the corresponding section of the cart of the digital menu or in the QRWaiter application.
      </p>
      <p>
        6.2. Self-delivery of goods:
      </p>
      <p>
        6.2.1. After placing of the Order, the Buyer can pay and receive the goods at the address of the catering establishment, or at the address indicated during the ordering process via the digital menu or the QRWaiter application.
      </p>
      <p>
        6.2.2. Ownership and the risk of accidental loss or damage to the goods shall be transferred to the Buyer or representative thereof at the moment when the goods are received by the Buyer.
      </p>
      <p>
        6.3. Delivery / transfer of the goods to the Buyer shall be carried out by the catering establishment on its own in accordance with the delivery conditions, or with the involvement of the third parties (carrier).
      </p>
      <p>
        6.4. Upon receipt of the goods, the Buyer shall check the quality and quantity thereof (the name of the goods, quantity, completeness, expiration date etc) in the presence of a representative of the catering establishment (or the delivery service).
      </p>
      <p>
        6.5. The Buyer or the Buyer's representative, upon acceptance of the goods, is obliged to inspect the goods, and in case of any claims related to the quantity of goods, outlook appearance and a range of the goods, the Buyer shall immediately make a relevant reclamation. In the event that such reclamation is not immediately made upon acceptance of the Goods, the Buyer shall not be entitled to make any claims later, unless otherwise is set out in section VII hereof.
      </p>
      <p>
        6.6. The Seller will aim to provide the Buyer with the ordered products as close as possible to the requested delivery/collection time but the Seller cannot guarantee the delivery time in all the cases. Delivery time may be affected due to bad weather or traffic conditions. This is to ensure the safety of the riders. Delivery service may be temporarily unavailable in selected areas due to bad weather or unforeseen circumstances.
      </p>
      <p>
        6.7. The Buyer agrees to accept delivery of the Products at the agreed time and place of delivery. If the Buyer has chosen for the Products to be delivered, the Seller will deliver the order to the main entrance of the delivery address but any deliveries carried into the delivery address will only be made if the driver and you consent to this. If you are not present to take delivery of the Goods at the address given in your order, then the Seller will not refund the Buyer the price for the order and will charge the Buyer for the full amount of the order. 
      </p>
      <p>
        6.8. Delivery orders are also subject to: 1) the Buyer’s address falling in the defined delivery area of the Seller; 2) Availability of the Seller being online for accepting Orders; iii) the Buyer’s Order may be subject to a minimum amount per Order.
      </p>
      <h4>
        VІI. RETURN OF THE GOODS
      </h4>
      <p>
        7.1. The Buyer has the right to refuse the non-excisable goods at any time before they have been transferred, and after the transfer of the non-excisable goods such refusal can be exercised in the manner and under the conditions as set out by the applicable laws. 
      </p>  
      <p>
        7.2. The return of a non-excisable product of good quality is possible if the appearance, consumer properties thereof, as well as a document confirming the purchase and order of the relevant product have been preserved.
      </p>
      <p>
        7.3. The Buyer has no right to refuse goods of good quality, which have individually defined properties, if the relevant goods can be used exclusively by the Buyer who purchased them (including non-standard dimensions or other specific properties requested by the Buyer). The product shall be deemed as having individually defined properties if it differs in size or other characteristics from those indicated in the digital menu or application.
      </p>
      <p>
        7.4. If the Buyer refuses to purchase a non-excisable product of good quality, the Seller shall provide a refund in the amount of the cost paid for such Goods, excluding the seller's expenses for the delivery of the goods that are being returned.
      </p>
      <p>
        7.5. The return of the amount specified in clause 7.4. hereof shall be carried out at the same time when the goods are returned.
      </p>
      <h4>
        VIIІ. LIABILITY OF THE PARTIES
      </h4>
      <p>
        8.1. The Seller shall not be liable for damage caused to the Buyer as a result of improper use of the Goods ordered using the QR Service (digital menu / QRWaiter Application) and purchased from the Seller.
      </p>
      <p>
        8.2. The Seller is not responsible for improper or late delivery of the Goods (Order processing) and any failure to fulfil obligations in case the Buyer has provided inaccurate or erroneous information when placing an Order.
      </p>
      <p>
        8.3. The Seller and the Buyer are responsible for fulfilling their obligations in accordance with the current applicable legislation and the provisions of this Agreement.
      </p>
      <p>
        8.4. The Seller or the Buyer shall be released from liability for full or partial non-performance of obligations if the said non-performance is the result of force majeure such as war or hostilities, earthquake, flood, fire and other natural disasters that occurred independently of the will of the Seller and / or Buyer upon conclusion of this agreement. A Party that is unable to fulfill its obligations shall immediately notify the other Party thereof.
      </p>
      <h4>
        ІХ. OTHER PROVISIONS
      </h4>
      <p>9.1. This agreement shall be governed by the relevant legislation of the European Union and/or other applicable laws of the state of residence of the Seller to this Agreement as the case may be, excluding the possibility of reference to conflict of laws.</p>
      <p>
        9.2. All disputes arising between the Buyer and the Seller are resolved through negotiations. In case of failure to resolve the dispute through negotiations, the Buyer and / or the Seller have the right to apply to the courts in accordance with applicable law of the European Union and relevant state of residence of the party to this Agreement.
      </p>
      <p>
        9.3. The Seller has the right to amend this Agreement unilaterally in accordance with paragraph 3.2.1. hereof. The amendments to the Agreement can also be made by mutual consent of the Parties in the manner as set out by the relevant applicable law.
      </p>
      <h4>Х. SELLER DETAILS</h4>
      <p>
        <b>{{ offert.company_type }} {{ offert.company_name }}</b>
        <br />
        company code: {{ offert.company_code }}
        <br />
        Location: {{ offert.company_address }}
        <br />
        Phone number:
        <a :href="`tel:${offert.company_phone}`">
          {{ offert.company_phone }}
        </a>
        <br />
        <!-- Адреса електронної пошти: <a href="mailto:team@qrwaiter.com.ua">team@qrwaiter.com.ua</a> -->
        <!-- <br> -->
        <br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offert: Object,
  },
};
</script>

<style>
</style>